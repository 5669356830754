<template>
  <div class="container-md col-lg-11 col-md-11 review-activity pt-2">
    <div class="d-flex">
      <h4 class="text-primary w-100">
        {{ isPendingActivity ? "Candidate Schedule" : "Candidate Interview" }}
      </h4>
      <CButton
        class="justify-content-end text-nowrap"
        color="primary"
        shape="pill"
        @click="backToList"
        >Back to list</CButton
      >
    </div>
    <div v-if="isPendingActivity" class="mt-3 mb-3">
      <ReviewActivity
        v-if="isPendingActivity"
        :activityDetails="selectedActivity"
        :comments="getComments"
        @changeActivityStatus="changeActivityStatus"
        :events="interviewEvents"
        @openCandidateInfoModal="openCandidateInfoModal"
        @openJobInfoModal="openJobInfoModal"
      />
      <h4 v-else class="text-muted p-4 text-center">
        No Pending Activity Available for Review
      </h4>
    </div>
    <div v-else class="mt-3 mb-3">
      <CompletedCandidateReReview
        v-if="isCompletedActivity"
        :activityDetails="selectedActivity"
        :comments="getComments"
        @changeActivityStatus="changeActivityStatus"
        @openCandidateInfoModal="openCandidateInfoModal"
        @openJobInfoModal="openJobInfoModal"
      />
      <h4 v-else class="text-muted p-4 text-center">
        No Pending Activity Available for Review
      </h4>
    </div>
    <CandidateInfoModal
      v-if="candidateInfoModal.isShowPopup"
      :candidate_uid="candidateInfoModal.candidate_uid"
      :isShowModal="candidateInfoModal.isShowPopup"
      :isShowContact="false"
      :enableAddScratchPad="false"
      @modalCallBack="candidateInfoModalCallBack"
    />
    <JobInfoModal
      v-if="jobInfoModal.isShowPopup"
      :job_id="jobInfoModal.job_id"
      :isShowModal="jobInfoModal.isShowPopup"
      @modalCallBack="jobInfoModalCallBack"
    />
  </div>
</template>
<script>
import ReviewActivity from "@/containers/ActivityList/CandidateInterviewer/ReviewActivity";
import CompletedCandidateReReview from "@/containers/ActivityList/CandidateInterviewer/CompletedCandidateReReview.vue";
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper.js";
import m from "moment";
import CandidateInfoModal from "@/components/reusable/CandidateInfoModal";
import JobInfoModal from "@/components/reusable/JobInfoModal";
export default {
  components: {
    ReviewActivity,
    CompletedCandidateReReview,
    CandidateInfoModal,
    JobInfoModal,
  },
  data() {
    return {
      candidateInfoModal: {
        isShowPopup: false,
        candidate_uid: null,
      },
      jobInfoModal: {
        isShowPopup: false,
        job_id: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "selectedActivity",
      "getComments",
      "activeSubTab",
      "activeTab",
      "getInterviewSlots",
    ]),
    isPendingActivity() {
      return (
        isObject(this.selectedActivity) &&
        !isEmptyObjectCheck(this.selectedActivity) &&
        [54].includes(this.selectedActivity.status_id) &&
        [51, 55].includes(this.selectedActivity.sub_status_id) &&
        this.selectedActivity?.selected_slot_date == null
      );
    },
    isCompletedActivity() {
      return (
        isObject(this.selectedActivity) &&
        !isEmptyObjectCheck(this.selectedActivity) &&
        [54].includes(this.selectedActivity.status_id) &&
        ([57, 47, null].includes(this.selectedActivity.sub_status_id) ||
          ([55].includes(this.selectedActivity.sub_status_id) &&
            this.selectedActivity?.selected_slot_date))
      );
    },
    interviewEvents() {
      const t = this.getInterviewSlots
        .filter((v) => v.selected_slot_date != null)
        .map((v) => {
          let start = new Date(v?.selected_slot_date);
          const startTimeSplit = v?.selected_slot_start.split(":");
          start.setHours(
            startTimeSplit[0],
            startTimeSplit[1],
            startTimeSplit[2]
          );

          let end = new Date(v?.selected_slot_date);
          const endTimeSplit = v?.selected_slot_end.split(":");
          end.setHours(endTimeSplit[0], endTimeSplit[1], endTimeSplit[2]);

          const title = v?.candidate?.full_name
            ? `${v?.candidate?.full_name}-${v?.candidate_display_uid}`
            : "--";
          return {
            start: this.formatDate(start, "YYYY-MM-DD HH:mm"),
            end: this.formatDate(end, "YYYY-MM-DD HH:mm"),
            title,
            content: `Interviewer: ${v?.assigned_to_users_name} \n Job: ${v?.job?.job_title}-${v?.job_display_uid} \n <i class="v-icon material-icons">work</i>`,
            class: "leisure",
          };
        });
      return t;
    },
  },
  methods: {
    ...mapActions([
      "candidateInterviewSchedule",
      "fetchSelectedJobActivity",
      "fetchSelectedActivityComments",
      "fetchInterviewSlot",
    ]),
    changeActivityStatus(payload) {
      this.candidateInterviewSchedule(payload);
    },
    backToList() {
      // this.isPendingActivity
      //   ? this.$store.commit("SET_ACTIVE_TAB", 0)
      //   : this.$store.commit("SET_ACTIVE_TAB", 1);
      this.$store.commit("SET_ACTIVE_TAB", this.activeTab);
      this.$store.commit("SET_ACTIVE_SUB_TAB", this.activeSubTab);
      this.$router.push({ path: `/list-activity` });
    },
    formatDate(date, pattern = "YYYY-MM-DD") {
      if (date) return m(date).format(pattern);
      return "--";
    },
    openCandidateInfoModal(candidate_uid) {
      if (candidate_uid) {
        this.candidateInfoModal.candidate_uid = candidate_uid;
        this.candidateInfoModal.isShowPopup = true;
      }
    },
    openJobInfoModal(job_id) {
      if (job_id) {
        this.jobInfoModal.job_id = job_id;
        this.jobInfoModal.isShowPopup = true;
      }
    },
    candidateInfoModalCallBack(action) {
      this.candidateInfoModal.isShowPopup = false;
    },
    jobInfoModalCallBack(action) {
      this.jobInfoModal.isShowPopup = false;
    },
  },
  async mounted() {
    const {
      params: { jobID, candidateID },
    } = this.$route;
    let job_id = jobID ? parseInt(jobID) : null;
    let candidate_uid = candidateID ? parseInt(candidateID) : null;
    this.fetchSelectedJobActivity({
      job_id,
      candidate_uid,
      action_id: 18,
      status_id__in: [54],
      sub_status_id__in: [57, 51, 55, 47, "null"],
    });
    this.fetchSelectedActivityComments({
      job_id,
      candidate_uid,
      action_id: 18,
      status_id__in: [54],
      sub_status_id__in: [57, 51, 47, 55, "null"],
    });
    await this.fetchInterviewSlot();
  },
};
</script>